<template>
  <div>
    <b-overlay
      id="overlay-background"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <div>
        <b-card-actions
          title="Filters"
          action-collapse
          collapsed
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Expense Date"
                label-for="v-ref-no"
              >
                <div class="position-relative">
                  <flat-pickr
                    id="v-ref-no"
                    v-model="filters['deposit_expenses.created_at']"
                    :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s',
                               defaultDate: [
                                 new Date().setHours(0, 0, 1, 0), // 12:01 AM today
                                 new Date().setHours(23, 59, 59, 0) // 11:59 PM today
                               ]
                    }"
                    class="form-control"
                    placeholder="Search"
                  />
                  <div
                    v-if="filters['deposit_expenses.created_at']"
                    class="flatpickr-cancel"
                    @click="clearDate('deposit_expenses.created_at')"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Deposit Date"
                label-for="v-ref-no"
              >
                <div class="position-relative">
                  <flat-pickr
                    id="v-ref-no"
                    v-model="filters['branch_deposits.created_at']"
                    :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s' }"
                    class="form-control"
                    placeholder="Search"
                  />
                  <div
                    v-if="filters['branch_deposits.created_at']"
                    class="flatpickr-cancel"
                    @click="clearDate('branch_deposits.created_at')"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Expense Type"
                label-for="v-ref-no"
              >
                <v-select
                  v-model="filters['expense_type_id']"
                  :options="expenseOptions"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select Expense"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Warehouse"
                label-for="current_warehouse"
              >
                <v-select
                  v-model="filters['warehouse_id']"
                  :options="warehouse"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select Warehouse"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Apply and Clear Filters -->
          <b-row class="mt-1">
            <b-col>
              <filter-buttons
                :filter-data="filters"
                :apply-filters="applyFilters"
                :clear-filters="clearFilters"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
      <div>
        <b-card
          no-body
        >
          <div
            class="m-2"
          >
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <div>
                  <ReportTableButtons
                    :items="items"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchBranchExpensesNoPagination"
                    @refresh="fetchBranchExpenses"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(deposit_expense_created_at)="data">
              {{ __dateTimeFormatter(data.value) }}
            </template>
            <template #cell(branch_deposit.created_at)="data">
              {{ __dateTimeFormatter(data.value) }}
            </template>
            <template #cell(amount)="data">
              {{ __numberWithCommas(data.value, true) }}
            </template>
            <template #cell(remark)="data">
              <div class="width-400">
                {{ data.value }}
              </div>
            </template>
            <template #cell(status.key)="data">
              <finance-status-badge
                :status-key="data.value"
                :finance-status="statusOptions"
              />
              <div
                v-if="data.item.is_reversed_deposit"
                class="mt-1 d-flex justify-content-center"
              >
                <b-tooltip
                  :target="data.item.id.toString()"
                  triggers="hover"
                  variant="danger"
                >
                  Rejected Once
                </b-tooltip>
                <b-avatar
                  :id="data.item.id.toString()"
                  size="20"
                  style="background-color: #ffb5b5"
                >
                  <feather-icon
                    icon="ChevronsLeftIcon"
                    class="text-body align-middle"
                  />
                </b-avatar>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-sm-start"
              >
                <label class="mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-1 width-125"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                md="9"
                class="d-flex align-items-center justify-content-sm-end"
              >
                <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :total-rows="(meta.total)? meta.total : 0"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  // BButton,
  BPagination,
  VBTooltip,
  BAvatar,
  BTooltip,

} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import FilterButtons from '@/components/Filter/ReportFilterButtons.vue'

const ReportRepository = RepositoryFactory.get('report')
const FinanceRepository = RepositoryFactory.get('finance')
const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    vSelect,
    flatPickr,
    FinanceStatusBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    // BButton,
    BPagination,
    BAvatar,
    BTooltip,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      warehouse: [],
      filters: {
        'deposit_expenses.created_at': `${new Date(new Date().setHours(0, 0, 1, 0)).toISOString()} to ${new Date(new Date().setHours(23, 59, 59, 0)).toISOString()}`,
      },
      tableData: [],
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 200],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      dataLoading: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'deposit_expense_created_at',
          label: 'Expense Date',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'expense_type.name',
          label: 'Expense Type',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'user',
          label: 'Added By',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'branch_deposit.ref_no',
          label: 'Deposit Ref No',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'branch_deposit.created_at',
          label: 'Deposit Date',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'warehouse_name',
          label: 'Warehouse',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'status.key',
          label: 'Deposit Status',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'remark',
          label: 'Remark',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
      ],
      json_fields: {
        'Expense Date': {
          field: 'deposit_expense_created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Expense Type': {
          field: 'expense_type.name',
          callback: value => `"${value}"`,
        },
        'Added By': {
          field: 'user',
          callback: value => `"${value}"`,
        },
        'Deposit Ref No': {
          field: 'branch_deposit.ref_no',
          callback: value => `"${value}"`,
        },
        'Deposit Date': {
          field: 'branch_deposit.created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        Warehouse: {
          field: 'warehouse_name',
          callback: value => `"${value}"`,
        },
        'Deposit Status': {
          field: 'status.name',
          callback: value => `"${value}"`,
        },
        Remark: {
          field: 'remark',
          callback: value => `"${value}"`,
        },
        Amount: {
          field: 'amount',
          callback: value => `"${value}"`,
        },
      },
      currentDate: '',
      todayFilterQuery: '',
      downloadReportName: 'Branch-Expenses-Report',
      items: [],
      expenseOptions: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchBranchExpenses()
      },
    },
    deep: true,
    page() {
      this.fetchBranchExpenses()
    },
    perPage() {
      this.fetchBranchExpenses()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.statusOptions = JSON.parse(localStorage.getItem('finance_statuses'))
    this.readDataforExpenseType()
    this.fetchBranchExpenses()
    this.readDataforWarehouse()
  },
  methods: {
    async fetchBranchExpenses() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await ReportRepository.getBranchExpensesReport(this.page, this.filterQuery, this.perPage))

        // fiter the default date or selected date for table data and meta data
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchBranchExpensesNoPagination() {
      try {
        const { data } = (await ReportRepository.getBranchExpensesReportNoPagiantion(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async readDataforExpenseType() {
      try {
        this.expenseLoading = true
        const { data } = (await FinanceRepository.getExpenseType()).data
        this.expenseOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.expenseLoading = false
    },
    async readDataforWarehouse() {
      try {
        const { data } = (await ResourceRepository.getBranchListDropdown()).data
        this.warehouse = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchBranchExpenses()
    },
    applyFilters() {
      this.fetchBranchExpenses()
    },
    clearFilters() {
      this.filters = {
        'deposit_expenses.created_at': `${new Date(new Date().setHours(0, 0, 1, 0)).toISOString()} to ${new Date(new Date().setHours(23, 59, 59, 0)).toISOString()}`,
      }
      this.$nextTick(() => {
        this.fetchBranchExpenses()
      })
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select';
@import '../../../@core/scss/vue/libs/vue-flatpicker';
</style>
